var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',{staticClass:"teal lighten-5"},[_c('v-card-title',[_c('i18n',{attrs:{"path":"dialogs.addService"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.$emit('input',false)}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1),_c('v-card-text',[_c('v-select',{attrs:{"prepend-icon":"mdi-human-scooter","label":_vm.$t('fields.service'),"placeholder":_vm.$t('fields.service'),"items":_vm.availableServices,"return-object":"","item-value":"idx","item-text":"info.name"},on:{"change":_vm.onService2addchanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters['session/testClubService'](item.idx)),expression:"$store.getters['session/testClubService'](item.idx)"}],staticClass:"mr-3",attrs:{"color":"success"}},[_vm._v("mdi-check")]),_c('span',[_vm._v(_vm._s(item.info.name))]),_c('span',[_vm._v(_vm._s(item.info.description))])]}}]),model:{value:(_vm.service2add),callback:function ($$v) {_vm.service2add=$$v},expression:"service2add"}}),(_vm.service2add)?_c('v-select',{attrs:{"prepend-icon":"mdi-grid","label":_vm.$t('fields.tariff'),"placeholder":_vm.$t('fields.tariff'),"items":_vm.serviceTariffs,"return-object":"","item-value":"idx","item-text":"info.name"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TariffInfo',{attrs:{"tariff":item}})]}}],null,false,809332597),model:{value:(_vm.tariff2add),callback:function ($$v) {_vm.tariff2add=$$v},expression:"tariff2add"}}):_vm._e(),_c('TariffInfo',{attrs:{"tariff":_vm.tariff2add,"large":"","service":_vm.service2add}}),(_vm.service2add && _vm.service2add.workout)?_c('v-select',{attrs:{"prepend-icon":"mdi-calendar-month","label":_vm.$t('fields.workout'),"placeholder":_vm.$t('fields.workout'),"items":_vm.availableWorkouts,"return-object":"","item-value":"idx","item-text":"info.name"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(item.info.name))]),_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"primary"}},[_vm._v(_vm._s(_vm.$t("icons.coachs")))]),_c('sc-record-info',{staticClass:"mr-2",attrs:{"idx":item.coach,"store":"coachs/item"}}),_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"primary"}},[_vm._v(_vm._s(_vm.$t("icons.rooms")))]),_c('sc-record-info',{staticClass:"mr-2",attrs:{"idx":item.room,"store":"rooms/item"}}),_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"primary"}},[_vm._v(_vm._s(_vm.$t("icons.services")))]),_c('sc-record-info',{staticClass:"mr-2",attrs:{"idx":item.service,"store":"services/item"}}),_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"primary"}},[_vm._v(_vm._s(_vm.$t("icons.time")))]),_c('i18n',{attrs:{"path":"label.startAt"},scopedSlots:_vm._u([{key:"time",fn:function(){return [_vm._v(_vm._s(item.settings.time))]},proxy:true}],null,true)})],1),_c('div')]}}],null,false,3944339904),model:{value:(_vm.workout2assign),callback:function ($$v) {_vm.workout2assign=$$v},expression:"workout2assign"}}):_vm._e(),(_vm.tariff2add)?_c('v-select',{attrs:{"label":_vm.$t('fields.buytag'),"placeholder":_vm.$t('fields.buytag'),"items":_vm.$store.getters['dicts/tags'],"item-value":"name","multiple":"","chips":"","deletable-chips":"","item-text":"name","menu-props":"offsetY,closeOnContentClick"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),(item.details)?_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(item.details.name))]):_vm._e()]}}],null,false,496893671),model:{value:(_vm.tags2buy),callback:function ($$v) {_vm.tags2buy=$$v},expression:"tags2buy"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),(_vm.allowAddService)?_c('v-btn',{staticClass:"rounded-pill elevation-10",attrs:{"color":"success"},on:{"click":_vm.addService}},[_c('i18n',{attrs:{"path":"button.addservice"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }